import {useWindowSize} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'

export type HeroSectionContentItem = IContentItem<{
	title: Elements.TextElement
	subtitle: Elements.RichTextElement
	image: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
	backgroundImage: Elements.MultipleChoiceElement
	imageLeftOrRight: Elements.MultipleChoiceElement
}>

export const HeroSectionBlock: Block<HeroSectionContentItem> = ({
	block: {
		elements: {imageLeftOrRight, image, mobileImage, subtitle, title},
	},
	...context
}) => {
	const authState = useAuth()

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call -- TODO: fix component library types
	const size = useWindowSize() as {isMobile: boolean}

	const reversed = imageLeftOrRight[0]?.codename === 'left'

	return (
		<div
			className={clsx(
				`flex`,
				reversed
					? 'flex-col-reverse md:flex-row-reverse'
					: 'flex-col md:flex-row',
				`container-wide relative z-[-1] py-4 md:py-0 lg:-mb-10 lg:-mt-16`
			)}
		>
			<div
				className={clsx(
					`flex`,
					reversed ? 'md:justify-end' : null,
					`md:basis-1/2 md:items-center md:justify-center`
				)}
			>
				<div
					className={clsx(
						`w-full py-4 md:max-w-md`,
						reversed ? 'md:px-4' : null
					)}
				>
					{title ? (
						<h1 className="mb-2 text-4xl font-semibold text-midnight-blue">
							{title.replaceAll(
								/{{petOwner}}/gi,
								authState.userDetails.firstName
							)}
						</h1>
					) : null}
					<RichTextRenderer
						className="text-lg text-charcoal lg:text-xl"
						element={subtitle}
						{...context}
					/>
				</div>
			</div>
			<div
				className={clsx(
					reversed ? 'justify-end' : null,
					`md:basis-1/2`
				)}
			>
				{size.isMobile && mobileImage[0] ? (
					<Image
						alt={mobileImage[0].description || ''}
						className="max-h-[285px] w-full object-contain md:max-h-[380px]"
						height={mobileImage[0].height ?? 380}
						loader={loadImageFromKontentAI}
						priority
						src={mobileImage[0]?.url ?? ''}
						width={mobileImage[0]?.width ?? 380}
					/>
				) : (
					<>
						{image[0] ? (
							<Image
								alt={image[0].description || ''}
								className="max-h-[285px] w-full object-contain md:max-h-[380px]"
								height={image[0].height ?? 380}
								loader={loadImageFromKontentAI}
								priority
								src={image[0].url}
								width={image[0].width ?? 380}
							/>
						) : null}
					</>
				)}
			</div>
		</div>
	)
}
