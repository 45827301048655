import React, {cloneElement} from 'react'
import type {ReactElement, ReactNode} from 'react'

const iconSizes = {
	none: 'h-auto w-auto',
	xs: 'h-[35px] w-[35px] border-3',
	sm: 'h-[55px] w-[55px] border-3',
	md: 'h-20 w-20 border-[5px]',
	lg: 'h-[120px] w-[120px] border-[7px]',
}

interface IconProps {
	boldTitle?: boolean
	children: ReactElement<{className?: string}>
	className?: string
	noBackground?: boolean
	noBorder?: boolean
	noShadow?: boolean
	showSquareIcon?: boolean
	size?: keyof typeof iconSizes
	title?: ReactNode
	iconCustomClasses?: string
}

export const Icon = (props: IconProps): JSX.Element => {
	const {
		boldTitle = false,
		children,
		className = '',
		noBackground = false,
		noBorder = false,
		noShadow = false,
		showSquareIcon = false,
		size = 'lg',
		title = '',
	} = props

	const icon = cloneElement(children, {
		className: children.props.className || 'w-full',
	})

	const iconClasses = `${
		noBackground ? 'bg-transparent' : 'bg-white'
	} text-theme-highlight ${noShadow ? '' : 'shadow-secondary'}  ${
		noBorder ? 'border-none' : 'border-theme-main'
	} ${
		showSquareIcon ? '' : 'rounded-[50%]'
	} flex items-center justify-center overflow-hidden [&>img]:mb-0 ${
		iconSizes[size]
	} ${'iconCustomClasses' in props ? props.iconCustomClasses : ''}`

	return (
		<div className={`flex flex-col items-center ${className}`}>
			<div className={iconClasses}>{icon}</div>
			{title ? (
				<div
					className={`mb-4 mt-6 w-full font-${
						boldTitle ? 'bold' : 'normal'
					} text-main leading-tight`}
				>
					{title}
				</div>
			) : null}
		</div>
	)
}
