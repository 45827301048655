import {useEffect, useState} from 'react'
import {ArticleHeader, Image, useWindowSize} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {getContainerClass} from '@/components/BlockMapper/utils'

export type ArticleHeaderBlockContentItem = IContentItem<{
	image: Elements.AssetsElement
	articleMobileImage: Elements.AssetsElement
	snippetContainerResize: Elements.MultipleChoiceElement
}>

export const ArticleHeaderBlock: Block<ArticleHeaderBlockContentItem> = ({
	block,
}) => {
	const image = block.elements.image[0]
	const mobileImage = block.elements.articleMobileImage[0]
	const containerSize = getContainerClass(
		block.elements.snippetContainerResize[0]?.codename ?? ''
	)
	const [headerState, setHeaderState] = useState<{
		articleheaderImage: TersedAsset | null
	}>({
		articleheaderImage: null,
	})
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- TODO: fix component library types
	const size = useWindowSize()
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
		if (size.isMobile) {
			if (mobileImage) {
				setHeaderState({
					articleheaderImage: mobileImage,
				})
			} else if (image) {
				setHeaderState({
					articleheaderImage: image,
				})
			} else {
				setHeaderState({
					articleheaderImage: null,
				})
			}
		} else {
			setHeaderState({
				articleheaderImage: image ?? null,
			})
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
	}, [image, mobileImage, size.isMobile])

	return (
		<div>
			<ArticleHeader
				containerClassName={containerSize}
				data-kontent-element-codename="article_header_block"
				withSpacingBottom={false}
				withSpacingTop={false}
			>
				{headerState.articleheaderImage ? (
					<Image
						alt={headerState.articleheaderImage.description ?? ''}
						height={headerState.articleheaderImage.height ?? 500}
						src={headerState.articleheaderImage.url}
						width={headerState.articleheaderImage.width ?? 750}
					/>
				) : null}
			</ArticleHeader>
		</div>
	)
}
