import type {ReactNode} from 'react'
import {Icon} from '@elanco/component-library-v2'
import {clsx} from 'clsx'

interface SimpleContentBlocksProps {
	className?: string
	iconSvg?: ReactNode
	title?: string
	description?: ReactNode
	isLargeIconSvg?: boolean
}

export const SimpleContentBlock: React.FC<SimpleContentBlocksProps> = ({
	className,
	iconSvg,
	title,
	description,
	isLargeIconSvg,
}) => {
	return (
		<div className={className}>
			<div className="flex flex-col items-center md:flex-row md:items-start">
				{iconSvg ? (
					<Icon size={isLargeIconSvg ? 'lg' : 'md'}>{iconSvg}</Icon>
				) : null}
				<div className={clsx(iconSvg && 'ml-4 md:ml-6', 'flex-1')}>
					<h3 className="text-xl md:text-2xl">{title}</h3>
					<div className="mt-4 md:mt-0">{description}</div>
				</div>
			</div>
			{!iconSvg && <div className="mt-4 md:mt-6">{description}</div>}
		</div>
	)
}
