import type {Dispatch, SetStateAction} from 'react'
import {useEffect} from 'react'
import {
	useWindowSize,
	EllipseButton,
	ButtonIcons,
} from '@elanco/component-library-v2'
import type {
	GlobalConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import type {HeaderState} from '../helpers'
import {PrimaryButtons} from './buttons'
import {ShowSearchButton} from './show-search-button'

export const ToggleNav = ({
	globalConfig,
	headerState,
	setHeaderState,
	toggleSearchPopup,
}: {
	globalConfig: Tersed<GlobalConfigContentItem>
	headerState: HeaderState
	setHeaderState: Dispatch<SetStateAction<HeaderState>>
	toggleSearchPopup: () => void
}): JSX.Element => {
	const headerConfig = globalConfig.elements.headerConfig[0]
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call -- TODO: fix component library types
	const {isMobile, isTablet} = useWindowSize() as {
		isMobile: boolean
		isTablet: boolean
	}

	const toggleNav = (): void => {
		setHeaderState((currentState) => ({
			...currentState,
			navVisible: !currentState.navVisible,
		}))
	}

	useEffect(() => {
		if (isMobile || isTablet) {
			document.body.style.overflow = headerState.navVisible
				? 'hidden'
				: 'unset'
		}
	}, [headerState.navVisible, isMobile, isTablet])

	// eslint-disable-next-line react/jsx-no-useless-fragment -- TODO: necessary until react types are fixed
	if (!headerConfig) return <></>

	const primaryNavHasLinks = Boolean(headerConfig.elements.links.length)

	const secondaryHeaderConfig = headerConfig.elements.secondaryHeaderConfig[0]
	const secondaryNavHasLinks = Boolean(
		secondaryHeaderConfig?.elements.links.length
	)

	return (
		<>
			<div className="flex flex-row lg:!invisible lg:!hidden">
				<ShowSearchButton
					searchAccessibilityText={
						headerConfig.elements.searchAccessibilityText
					}
					showSearch={
						headerConfig.elements.showSearch[0]?.codename === 'yes'
					}
					toggleSearchPopup={toggleSearchPopup}
				/>

				{primaryNavHasLinks || secondaryNavHasLinks ? (
					<EllipseButton
						as="button"
						className="ml-4 border border-white"
						icon={
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: fix component library types
							headerState.navVisible
								? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
									ButtonIcons.Close
								: // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
									ButtonIcons.Nav
						}
						onClick={() => {
							toggleNav()
						}}
						size="sm"
					/>
				) : null}
			</div>
			<div className="invisible hidden lg:!visible lg:!flex">
				<div className="flex gap-4">
					<PrimaryButtons
						buttonLinks={headerConfig.elements.buttonLinks}
						loginUrl={globalConfig.elements.loginUrl}
						myAccountWrapper={
							headerConfig.elements.myAccountWrapperParent[0]
						}
						variant="inverted"
					/>
				</div>
				<ShowSearchButton
					searchAccessibilityText={
						headerConfig.elements.searchAccessibilityText
					}
					showSearch={
						headerConfig.elements.showSearch[0]?.codename === 'yes'
					}
					toggleSearchPopup={toggleSearchPopup}
				/>
			</div>
		</>
	)
}
