import {useState, useEffect} from 'react'
import Sticky from 'react-stickynode'
import {useWindowSize, Bar} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {customBrands} from '@/components/BlockMapper/utils'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type StickyBarContentItem = IContentItem<{
	brand: Elements.MultipleChoiceElement
}>

export const StickyBarBlock: Block<StickyBarContentItem> = ({block}) => {
	const [topOffset, setTopOffset] = useState(0)
	// type def found here: https://github.com/elanco/design-system/blob/905e24094727568ed5ce92a2e4de4cd81166771a/src/hooks/useWindowSize.ts#L15-L22
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call -- TODO: Fix component library types
	const size = useWindowSize() as {
		width: number | undefined
		height: number | undefined
		isMobile: boolean | undefined
		isTablet: boolean | undefined
	}

	useEffect(() => {
		const secondaryHeader = document.querySelector(
			'[data-secondary-header]'
		)
		const hasSecondaryHeader =
			secondaryHeader &&
			secondaryHeader instanceof HTMLElement &&
			!size.isMobile
		const newTopOffset = hasSecondaryHeader
			? 48 + secondaryHeader.scrollHeight
			: 48
		if (newTopOffset !== topOffset) {
			setTopOffset(newTopOffset)
		}
	}, [size.isMobile, topOffset])

	const selectedBrandOrFallback =
		block.elements.brand[0]?.codename ?? 'primary'
	const className = customBrands[selectedBrandOrFallback]?.background ?? ''

	return (
		<div data-kontent-element-codename={block.system.codename}>
			<Sticky bottomBoundary="#bottom-bar" innerZ="1" top={topOffset}>
				{({status}) => (
					<Bar
						brand={selectedBrandOrFallback}
						className={className}
						fixed={status === Sticky.STATUS_FIXED}
					/>
				)}
			</Sticky>
		</div>
	)
}
