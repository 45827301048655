import {cloneElement, isValidElement, Children} from 'react'
import {Image, Button, VideoBlock} from '@elanco/component-library-v2'
import {clsx} from 'clsx'
import {LargeVisualBlock} from '../large-visual-block/large-visual-block'

interface FeatureBlockProps {
	children?: React.ReactNode
	reversed?: boolean
	className?: string
}

export const FeatureBlock: React.FC<FeatureBlockProps> = ({
	children = null,
	reversed = false,
	className = '',
}) => {
	const childrenArray = Children.toArray(children)

	const image = childrenArray.find((child) => {
		return (
			isValidElement(child) &&
			(child.type === Image ||
				child.type === LargeVisualBlock ||
				child.type === VideoBlock)
		)
	})

	const button = childrenArray.find((child) => {
		return isValidElement(child) && child.type === Button
	})

	const aside = childrenArray.find(
		(child): child is React.ReactElement<{children?: React.ReactNode}> =>
			isValidElement(child) && child.type === 'aside'
	)

	const styledAside = (): React.ReactNode => {
		if (aside && aside.props.children !== undefined) {
			const {children: asideChildren} = aside.props

			if (!Array.isArray(asideChildren)) {
				return isValidElement(asideChildren)
					? cloneElement(
							asideChildren as React.ReactElement<{
								className?: string
							}>,
							{
								className:
									'bg-blue-900 z-10 h-16 w-16 mb-2 flex justify-center items-center',
								key: asideChildren.key,
							}
						)
					: null
			}

			// Explicitly cast asideChildren as ReactNode[] for array mapping
			return (asideChildren as React.ReactNode[]).map((element) =>
				isValidElement(element)
					? cloneElement(
							element as React.ReactElement<{className?: string}>,
							{
								className:
									'bg-blue-900 z-10 h-16 w-16 mb-2 flex justify-center items-center',
								key: element.key,
							}
						)
					: element
			)
		}
		return null
	}

	const infoSection = childrenArray.filter((child) => {
		return (
			isValidElement(child) &&
			child.type !== Image &&
			child.type !== Button &&
			child.type !== LargeVisualBlock &&
			child.type !== VideoBlock &&
			child.type !== 'aside'
		)
	})

	let imgDivClass = ''

	if (
		image &&
		isValidElement(image) &&
		(image.type === LargeVisualBlock || image.type === VideoBlock)
	) {
		imgDivClass = 'rounded-lg shadow-secondary'
	}

	return (
		<div className={`container-wide relative ${className}`}>
			<div
				className={clsx(
					'absolute h-full w-full rounded-lg bg-gray-100 md:w-3/5',
					reversed && 'right-0'
				)}
			/>
			<div
				className={clsx(
					'z-1 relative items-start justify-between px-6 py-8 md:flex md:px-0 md:py-12',
					reversed
						? 'flex-row-reverse md:pr-10 lg:pr-20'
						: 'md:pl-10 lg:pl-20'
				)}
			>
				<div className="md:w-1/3">
					{infoSection}
					<div
						className={`relative mt-8 overflow-hidden rounded-lg md:hidden ${imgDivClass} mb-6`}
					>
						<div
							className={clsx(
								'absolute right-0 z-10 mr-4 mt-4 text-center text-white',
								aside ? '' : 'hidden'
							)}
						>
							{styledAside()}
						</div>
						{image}
					</div>
					{button}
				</div>

				<div
					className={`relative hidden overflow-hidden rounded-lg md:block md:w-3/5 ${imgDivClass}`}
				>
					<div
						className={clsx(
							'absolute right-0 z-10 mr-4 mt-4 text-center text-white',
							aside ? '' : 'hidden'
						)}
					>
						{styledAside()}
					</div>
					{image}
				</div>
			</div>
		</div>
	)
}
