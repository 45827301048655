import React from 'react'

interface Column {
	title: string | null
	id: string
}

interface Header {
	columns: Column[]
}

interface RowData {
	content: React.ReactNode
	id: string
}

interface Row {
	data: RowData[]
	id: string
}

interface Table {
	headers: Header[]
	rows: Row[]
}

interface TableProps {
	className?: string
	mobileClassName?: string
	table: Table
}

export const TableBlock: React.FC<TableProps> = ({
	table,
	className = '',
	mobileClassName = '',
}) => {
	return (
		<div>
			<table
				className={`w-full text-center md:hidden ${mobileClassName}`}
			>
				<tbody>
					{table.rows.map((row) =>
						row.data.map((data, x) => (
							<tr key={data.id}>
								<td className="p-4">
									{table.headers[0]?.columns[x]?.title} -{' '}
									{data.content}
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>

			<table
				className={`hidden w-full text-center md:table ${className}`}
			>
				<thead>
					{table.headers.map((header) => (
						<tr key={header.columns[0]?.id}>
							{header.columns.map((column) => {
								const headerClasses = column.title
									? 'bg-blue-700 p-6 text-white'
									: ''
								return (
									<th
										className={headerClasses}
										key={column.id}
									>
										{column.title === null ? (
											<span>&nbsp;</span>
										) : (
											column.title
										)}
									</th>
								)
							})}
						</tr>
					))}
				</thead>
				<tbody>
					{table.rows.map((row) => (
						<tr key={row.id}>
							{row.data.map((data) => (
								<td className="p-4" key={data.id}>
									{data.content}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}
