import Image from 'next/future/image'
import React from 'react'
import {isServer} from '@/utils/helpers'
import type {TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {loadImageFromKontentAI} from '@/imageLoader'
import {Icon} from '../components'

interface CarouselCTAProps {
	title: string
	link: string
	icon: TersedAsset[]
}

export const CarouselCTA: React.FC<CarouselCTAProps> = ({
	title,
	icon,
	link,
}) => {
	const selected = !isServer() && link === window.location.href
	return (
		<a className="cursor-pointer" href={link}>
			<div
				className={`${
					selected
						? 'bg-elanco-blue hover:bg-blue-400'
						: 'bg-pet-green hover:bg-green-400'
				} h-32 w-full border-2 border-solid border-white p-5`}
			>
				<Icon noBackground noBorder noShadow size="sm">
					<Image
						alt={icon[0]?.description || ''}
						className="h-full"
						height={55}
						loader={loadImageFromKontentAI}
						src={icon[0]?.url || ''}
						width={55}
					/>
				</Icon>
				<p className="truncate text-center text-white">
					{title.toUpperCase()}
				</p>
			</div>
		</a>
	)
}
