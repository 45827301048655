import {useState, useEffect} from 'react'
import {Calendar} from 'react-calendar'
import {EllipseButton, ButtonIcons} from '@elanco/component-library-v2'

interface ButtonIconsType {
	ArrowRight: string
	ArrowLeft: string
}

interface CalendarBlockProps {
	dates?: string[]
	className?: string
	onClick?: (date: Date) => void
	iconActiveColor?: string
	activeDateColor?: string
	btnVariant?: string
	hideNeighboringMonth?: boolean
	progressHandler?: (activeStartDate: Date, value: Date, view: string) => void
	selectedDate?: Date
}

export const CalendarBlock: React.FC<CalendarBlockProps> = ({
	dates = null,
	className = '',
	onClick = () => {
		// Default behavior for onClick
	},
	progressHandler = () => {
		// Default behavior for progressHandler
	},
	iconActiveColor = 'border-primary',
	activeDateColor = 'bg-blue-100',
	btnVariant = 'primary',
	hideNeighboringMonth = false,
	selectedDate = new Date(),
}) => {
	const [activeDate, setActiveDate] = useState<string>(
		selectedDate.toUTCString()
	)

	useEffect(() => {
		setActiveDate(selectedDate.toUTCString())
	}, [selectedDate])

	const getTimezoneDate = (date: Date): Date => {
		const tz = date.getTimezoneOffset()
		const hours = date.getHours()
		const mins = date.getMinutes()
		const tzHours = tz / 60
		const tzMins = tz % 60
		if (tz > 0) date.setHours(hours + tzHours, mins + tzMins)
		return date
	}

	const setActiveDateForMonthYear = (
		newDate: Date,
		currentDate: Date
	): void => {
		// only setActiveDate if the month or year is changed
		if (
			newDate.getMonth() !== currentDate.getMonth() ||
			newDate.getFullYear() !== currentDate.getFullYear()
		) {
			const nextEventDate = dates?.find((dtStr) => {
				const dt = new Date(dtStr)
				return (
					dt.getMonth() === newDate.getMonth() &&
					dt.getFullYear() === newDate.getFullYear()
				)
			})
			if (nextEventDate) {
				setActiveDate(new Date(nextEventDate).toUTCString())
				onClick(getTimezoneDate(new Date(nextEventDate)))
			} else {
				setActiveDate(newDate.toUTCString())
			}
		}
	}

	const addClassNames = (date: Date): string => {
		let classValue = ''

		if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
			classValue += `${activeDateColor} rounded-md outline-none today`
		}

		if (
			dates?.find(
				(d) =>
					date.toLocaleDateString() ===
					getTimezoneDate(new Date(d)).toLocaleDateString()
			)
		) {
			classValue += ' relative has-reminder'
		}

		return classValue
	}

	const addTileContent = (date: Date): JSX.Element | null => {
		if (
			dates?.find(
				(d) =>
					date.toLocaleDateString() ===
					getTimezoneDate(new Date(d)).toLocaleDateString()
			)
		) {
			return (
				<div
					className={`border-3 absolute inset-0 z-0 m-auto h-10 w-10 rounded-full ${iconActiveColor} sm:h-12 sm:w-12`}
				/>
			)
		}
		return null
	}

	return (
		<div className={className}>
			<Calendar
				activeStartDate={new Date(activeDate)}
				className="p-4"
				defaultValue={new Date(activeDate)}
				maxDetail="month"
				next2Label={null}
				nextLabel={
					<EllipseButton
						icon={(ButtonIcons as ButtonIconsType).ArrowRight} // Ensure ButtonIcons is properly typed
						noIconAnimation
						size="sm"
						variant={btnVariant}
					>
						Next Month
					</EllipseButton>
				}
				onActiveStartDateChange={({
					activeStartDate,
					value,
					view,
				}: {
					activeStartDate: Date
					value: Date
					view: string
				}) => {
					setActiveDateForMonthYear(activeStartDate, value)
					progressHandler(activeStartDate, value, view)
				}}
				onClickDay={(value: Date) => {
					setActiveDate(value.toUTCString())
					onClick(value)
				}}
				prev2Label={null}
				prevLabel={
					<EllipseButton
						icon={(ButtonIcons as ButtonIconsType).ArrowLeft}
						noIconAnimation
						size="sm"
						variant={btnVariant}
					>
						Previous Month
					</EllipseButton>
				}
				showNeighboringMonth={!hideNeighboringMonth}
				tileClassName={({date}: {date: Date}) => addClassNames(date)}
				tileContent={({date}: {date: Date}) => addTileContent(date)}
				value={new Date(activeDate)}
				view="month"
			/>
		</div>
	)
}
