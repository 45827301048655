import {
	HeaderBlockWithCarousel,
	HeaderBlockCarouselSlide,
	useWindowSize,
} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'

type HeaderBlockCarouselSlideContentItem = IContentItem<{
	heading: Elements.TextElement
	subheading: Elements.TextElement
	headingUrl: Elements.TextElement
	imgUrl: Elements.TextElement
	image: Elements.AssetsElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	isContentOnTopOfImage: Elements.MultipleChoiceElement
	mobileImage: Elements.AssetsElement
}>

export type HeaderBlockCarouselContentItem = IContentItem<{
	headerBlockCarouselSlides: Elements.LinkedItemsElement<HeaderBlockCarouselSlideContentItem>
}>

export const HeaderBlockCarouselBlock: Block<
	HeaderBlockCarouselContentItem
> = ({block, ...context}) => {
	const {locale} = useRouter()
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call -- TODO: fix Component Library types
	const size = useWindowSize() as {isMobile: boolean}

	return (
		<HeaderBlockWithCarousel>
			{block.elements.headerBlockCarouselSlides.map((slide) => {
				const isContentOnTopOfImage =
					slide.elements.isContentOnTopOfImage[0]?.codename === 'yes'
				const button = slide.elements.button[0]
				const mobileImage = slide.elements.mobileImage[0] ?? null
				const image = slide.elements.image[0] ?? null
				const slideImage =
					size.isMobile && mobileImage ? mobileImage : image

				return (
					<HeaderBlockCarouselSlide
						button={
							button ? (
								<ReusableCTAButtonComponent
									block={button}
									className={
										isContentOnTopOfImage
											? 'w-max lg:w-2/3'
											: undefined
									}
									key={button.system.id}
									{...context}
								/>
							) : null
						}
						heading={slide.elements.heading}
						headingUrl={addLocalePrefix(
							slide.elements.headingUrl || '/',
							locale ?? ''
						)}
						image={
							slideImage ? (
								<Image
									alt={slideImage.description ?? ''}
									height={slideImage.height ?? 552}
									loader={loadImageFromKontentAI}
									priority
									src={slideImage.url}
									width={slideImage.width ?? 1988}
								/>
							) : null
						}
						isContentOnTopOfImage={isContentOnTopOfImage}
						key={slide.system.id}
						subheading={slide.elements.subheading}
						url={addLocalePrefix(
							slide.elements.imgUrl || '/',
							locale ?? ''
						)}
					/>
				)
			})}
		</HeaderBlockWithCarousel>
	)
}
